import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Menu, MenuItem} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import '../App.css';

export default class MenuNav extends Component{

    constructor(){
        super();
        this.state = {
            menus: [],
            openMenuUser: false,
            anchorEl: null
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount(){
        // this.getMenus();
    }

    // getMenus = () => {
	// 	menusService.getForMenu()
	// 	.then(docs => {
	// 		console.log(docs);
    //         let menus = [];
    //         docs.forEach(item => {
    //             if(item.path){
    //                 menus.push(item);
    //             }
    //         });
	// 		this.setState({menus})
	// 	});
	// }

    handleClick(event){
        console.log('llega aquu');
        this.setState({
            anchorEl: event.currentTarget,
            openMenuUser: !this.state.openMenuUser
        })
    };
    
    handleClose(){
        this.setState({anchorEl: null, openMenuUser: false})
    };

    render(){
        return(
            <div>
                <IconButton 
                    aria-label="menu"
                    aria-controls={this.state.openMenuUser ? 'mobile-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={this.state.openMenuUser ? 'true' : undefined}
                    onClick={this.handleClick}
                >
                    <MenuIcon style={{color: '#fff'}}/>
                </IconButton>
                {/* <Menu
                    id="mobile-menu"
                    MenuListProps={{
                    'aria-labelledby': 'long-button',
                    }}
                    anchorEl={this.state.anchorEl}
                    open={this.state.openMenuUser}
                    onClose={this.handleClose}
                    onClick={this.handleClose}
                    PaperProps={{
                    style: {
                        maxHeight: 300,
                        width: '20ch',
                    },
                    }}
                >
                    {this.state.menus.map((menu, index) => (
                        <MenuItem key={'Mi'+index} component={Link} to={menu.path}>
                            {menu.name}
                        </MenuItem>
                    ))}
                </Menu> */}
            </div>
        )
    }
}
