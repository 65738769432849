import React, {Component} from 'react';
import { Container, Grid, Button, Divider } from '@mui/material';
import '../App.css';
import Helper from '../utils/Helper';
import CourseCard from '../components/CourseCard';
import Footer from '../components/Footer';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default class Wellcome extends Component {
	
    constructor(props){
        super(props);
        this.helper = new Helper();
    }

    componentDidMount(){
        
    }
    
    redirect = (page) => () => {
        this.props.navigate(page);
    }
    
	render () {
        const dimensions = this.helper.dimensionWindow();
        let height = dimensions.height - 65;

		return (
            <div>
                <div style={{height: height, backgroundColor: '#0174DF'}}>
                    <div style={{height: '20%'}}></div>
                    <div className='card-start'>
                        <div style={{textAlign: 'center'}}>
                            <div className='title-block'>Conviértete en un experto en la lectura de lenguaje corporal</div>
                            <div className='subtext-principal'>
                                Aprende todo lo relacionado al análisis y lectura del 
                                lenguaje corporal y microexpresiones faciales.
                            </div>
                            <Button variant='contained' size="large" className='text-btn-init'>Comenzar ahora</Button>
                        </div>
                    </div>
                </div>
                <div style={{paddingTop: 20}}>
                    <Container>
                        <div style={{marginTop: 30, textAlign: 'center'}}>
                            <div className='title-block'>¿Por qué aprender con nosotros?</div>
                            <div className='subtext-block1'>
                                Somos la única plataforma e-learning en español especializada en lenguaje corporal
                                y microexpresiones faciales. Te aseguramos un aprendizaje total.
                            </div>
                        </div>
                        <Grid container spacing={2}>
            	            <Grid item container xs={12} md={4}>
                                <Grid item xs={3} md={3}>
                                    <div className='circle-block1'></div>
                                </Grid>
                                <Grid item xs={9} md={9}>
                                    <div className='title-item-block1'>Por expertos en la industria</div>
                                    <div className='text-item-block1'>
                                        Curso desarrollado por expertos <br/>
                                        en detección de engaño.
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} md={4}>
                                <Grid item xs={3} md={3}>
                                    <div className='circle-block1'></div>
                                </Grid>
                                <Grid item xs={9} md={9}>
                                    <div className='title-item-block1'>Soporte premium</div>
                                    <div className='text-item-block1'>
                                        Atención personalizada 24/7 en <br/>
                                        nuestros canales de atención. 
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} md={4}>
                                <Grid item xs={3} md={3}>
                                    <div className='circle-block1'></div>
                                </Grid>
                                <Grid item xs={9} md={9}>
                                    <div className='title-item-block1'>Acceso ilimitado</div>
                                    <div className='text-item-block1'>
                                        Compra tu curso completo por <br/>
                                        un año sin restricciones.
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div style={{marginTop: 70, paddingBottom: 50, backgroundColor: '#F6F5F5'}}>
                    <Container>
                        <div style={{marginTop: 30, backgroundColor: '#F6F5F5', paddingTop: 40, paddingBottom: 20}}>
                            <Grid container style={{marginBottom: 20}}>
            	                <Grid item xs={12} md={1.8}>Cursos disponibles</Grid>
            	                <Grid item xs={12} md={10.2} style={{paddingTop: 13}}><Divider/></Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <CourseCard
                                    title="Microexpresiones Basic"
                                    rating={4}
                                />
                                <CourseCard
                                    title="Microexpresiones Advance"
                                    rating={4}
                                />
                                <CourseCard
                                    title="Lenguaje corporal + Microexpresiones I & II"
                                    rating={4}
                                />
                            </Grid>
                        </div>
                    </Container>
                </div>
                <Footer/>
            </div>
        );
	}
}
