const firebaseConfig = {
  apiKey: "AIzaSyCkNtn9VP4HUK5-OByUrKVOlggGzYN-Q04",
  authDomain: "prueba-proyecto-16f43.firebaseapp.com",
  projectId: "prueba-proyecto-16f43",
  storageBucket: "prueba-proyecto-16f43.appspot.com",
  messagingSenderId: "175327117339",
  appId: "1:175327117339:web:90b58f8651bbdc624b78be",
  measurementId: "G-NT335MM224"
}

export default firebaseConfig;
