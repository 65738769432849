import React, {Component} from 'react';
import { Grid } from '@mui/material';
import {BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Login from '../screens/auth/Login';
import ChangePassword from '../screens/auth/ChangePassword';
import Register from '../screens/auth/Register';
import Wellcome from '../screens/Wellcome';

import Header from './Header';
import WrapperComponent from '../utils/WrapperComponent';


export default class Guest extends Component{

    constructor(){
        super();
    }

    render(){
        let heightBlock = window.screen.height - 60 - 144;
        return(
            <BrowserRouter>
                <Grid container>
                    <WrapperComponent item={Header}/>
                    <Grid item xs={12} md={12}>
                        <div className='container-div' style={{height: heightBlock}}>
                            <Routes>
                                <Route exact path="/" element={<WrapperComponent item={Wellcome}/>} />
                                <Route exact path="/login" element={<WrapperComponent item={Login}/>} />
                                <Route exact path="/register" element={<WrapperComponent item={Register}/>} />
                                <Route exact path="/changepassword" element={<WrapperComponent item={ChangePassword}/>} />
                            </Routes>
                        </div>
                    </Grid>
                </Grid>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </BrowserRouter>
        )
    }
}
