import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class RolesService extends BaseService {

    constructor(){
        super();
        this.collection = 'roles';
    }

    async getRolAsync(rolId){
        let rol = await firebase.firestore()
        .collection(this.collection)
        .doc(rolId).get();
        return rol.data();
    }
}

export const rolesService = new RolesService();