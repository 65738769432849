import React, {Component} from 'react';
import { styled } from '@mui/material/styles';
import { Container, Grid, Button, TextField, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';
import fondo from '../../assets/fondologin.jpg';
import { grey } from '@mui/material/colors';

import SectionSlider from '../../components/SectionSlider';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const styles = {
    paperContainer: {
        backgroundImage: `url(${fondo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    }
};

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
      backgroundColor: grey[700],
    },
}));

export default class ChangePassword extends Component{

    constructor(){
        super();
        this.state={
            email: '',
            error: {
                email: {error: false, text: '', required: true},
            },
            blockButton: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleInputBlur(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let error = {...this.state.error};
        if(error[name].required && (value === '' || value === 0)){
            error[name].error = true;
            error[name].text = 'este campo es obligatirio';
            this.setState({blockButton: true});
        }else{
            error[name].error = false;
            error[name].text = '';
            this.setState({blockButton: false});
        }
        let validEmail = /@/.test(value);
        if(!validEmail){
            error[name].error = true;
            error[name].text = 'Email incorrecto';
            this.setState({blockButton: true});
        }
        this.setState({error})

    }

    sendEmail(){
        if(this.validateFields()){
            firebase.auth().sendPasswordResetEmail(this.state.email)
            .then(()=>{
                toast.info(
                    'Solitud de cambio de contraseña correcto, revise su correo para continuar con el proceso',
                    {
                        onClose: () => {this.props.navigate('/');}
                    }
                );
                
            })
            .catch((err) => {
                toast.error('Error al solicitar cambio de clave, revisar conexión a internet');
            })
        }
    }

    validateFields(){
        let validation = true;
        let error = {...this.state.error};
        let validEmail = /@/.test(this.state.email);
        if(!validEmail){
            error.email.error = true;
            error.email.text = 'Email incorrecto';
            validation = false;
        }
        if(!validation){
            this.setState({error, blockButton: true})
        }else{
            this.setState({error, blockButton: false})
        }
        return validation;
    }

    render(){
        let showImage  = true;
        if(window.screen.width <= 480){
            showImage = false;
        }
        return(
            <Container>
                <div className="login-div">
                    <Card>
                        <CardContent>
                            <div>
                                <div className="div-element">
                                    <h5>CAMBIAR CONTRASEÑA</h5>
                                </div>
                                <div className="div-element">
                                    <TextField 
                                        name="email" 
                                        label="Email"
                                        value={this.state.email}
                                        error={this.state.error.email.error}
                                        helperText={this.state.error.email.error ? this.state.error.email.text : ''}
                                        fullWidth
                                        margin="normal"
                                        required={this.state.error.email.required}
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputBlur} 
                                    />
                                </div>
                                <div className="div-element">
                                    <ColorButton style={{width: '100%'}} 
                                        variant="contained" 
                                        onClick={this.sendEmail.bind(this)}
                                        disabled={this.state.blockButton}
                                    >Solicitar</ColorButton>
                                </div>
                                <div className="div-element" style={{textAlign: 'right'}}>
                                    <Link style={{textAlign: 'right'}} to='/login'>Regresar</Link>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Container>
        )
    }
}
