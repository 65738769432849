import React from 'react';
import { Grid, Card, CardContent, CardMedia, CardActions, Rating }  from '@mui/material';

import imgProject from '../assets/default.png';
import imgProject1 from '../assets/default1.png';
import imgProject2 from '../assets/default2.png';

class CourseCard extends React.Component {

    constructor(props){
        super(props);
        this.state ={
            tooltipOpen1: false,
            tooltipOpen2: false,
            permission: false
        }
    }

    render(){
        const { title, rating } = this.props;
        return(
          <Grid item xs={12} md={2.5}>
            <Card>
              <CardMedia
                component="img"
                height="165"
                image={imgProject1}
                alt="curso 1"
              />
              <CardContent style={{paddingTop: 10, paddingBottom: 0}}>
                <div className='title-item-block1' style={{height: 30}}>{title}</div>
                <div style={{marginTop: 10}}>
                  <Rating
                    name="simple-controlled"
                    value={rating}
                  />
                </div>
              </CardContent>
              <CardActions>
                <Grid container>
                  <Grid item xs={6} md={6}>
                    <div style={{fontSize: 12}}>6 horas</div>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <div style={{fontSize: 12, textAlign: 'right'}}>5 secciones</div>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        )
    }
}
export default CourseCard;