import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Guest from './navigation/Guest';
import Logged from './navigation/Logged';
import Admin from './navigation/Admin';
import Preloader from './components/Preloader';
import { rolesService } from './services/roles.service';
import { userService } from './services/user.service';

import firebaseConfig from './utils/Firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const firebaseApp = firebase.initializeApp(firebaseConfig);

const theme = createTheme({
  palette: {
    primary: {
      main: '#2C2C2C',
    },
    secondary: {
      main: '#FFBF00',
    },
  },
});

export default class App extends React.Component {

  constructor(){
    super();
    this.state = {
      isLogged: false,
      isAdmin: false,
      loader: false
    }
  }

  componentDidMount(){
    firebaseApp.auth().onAuthStateChanged((user) => {
      if(user !== null){
        // this.validateDataUser();
        this.validAdmin()
      }else{
        this.setState({isLogged: false, isAdmin: false, loader: true});
      }
    })
  }

  // validateDataUser(){
  //   let userDocument = userService.getUser(firebase.auth().currentUser.uid);
  //   userDocument.then(user => {
  //     this.getRolUser(user.data().rol);
  //   })
  // }

  validAdmin(){
    firebase.firestore().collection('admin')
    .get()
    .then(snapshot => {
      snapshot.forEach(element => {
        if(element.data().email == firebase.auth().currentUser.email){
          this.setState({isLogged: false, isAdmin: true, loader: true});
        }else{
          this.setState({isLogged: true, isAdmin: false, loader: true});
        }
      })
    });
  }

//   async getRolUser(idRol){
//     let rol = await rolesService.getRolAsync(idRol);
//     if(rol.rol == 'Superadmin'){
//       this.setState({isLogged: false, isAdmin: true, loader: true});
//     }else{
//       this.setState({isLogged: true, isAdmin: false, loader: true});
//     }
  
// }

  render(){
    const {loader,isLogged, isAdmin} = this.state;
    if(!loader){
      return (<Preloader/>);
    }else{
      if(!isLogged && !isAdmin){
        return (
          <ThemeProvider theme={theme}>
            <Guest/>
          </ThemeProvider>
        )
      }
      return (
        <ThemeProvider theme={theme}>
          {isLogged && <Logged/>}
          {isAdmin && <Admin/>}
        </ThemeProvider>
      )
    }
  }
}