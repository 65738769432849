import React, {Component} from 'react';
import { styled } from '@mui/material/styles';
import { Container, Grid, Button, TextField, Card, CardContent,
    InputAdornment, IconButton, Fab
} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';
import fondo from '../../assets/fondologin.jpg';
import { grey } from '@mui/material/colors';

import SectionSlider from '../../components/SectionSlider';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const styles = {
    paperContainer: {
        backgroundImage: `url(${fondo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    }
};

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
      backgroundColor: grey[700],
    },
}));

export default class Register extends React.Component{

    constructor(){
        super();
        this.state={
            email: '',
            name: '',
            password: '',
            confirm_password: '',
            showPassword: {
                pass: false,
                confirm: false
            },
            error: {
                email: {error: false, text: '', required: true},
                name: {error: false, text: '', required: true},
                password: {error: false, text: '', required: true},
                confirm_password: {error: false, text: '', required: true},
            },
            blockButton: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.register = this.register.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleInputBlur(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let error = {...this.state.error};
        error = this.setErrorField(error, name, false, '');
        if(error[name].required && (value === '' || value === 0)){
            error = this.setErrorField(error, name, true, 'Campo obligatirio');
        }else{
            if(name === 'email'){
                if(!this.validEmail(value)){
                    error = this.setErrorField(error, name, true, 'Email incorrecto');
                }
            }
    
            if(name === 'password'){
                if(value.length < 6){
                    error = this.setErrorField(error, name, true, 'La contraseña debe tener mas de 6 caracteres');
                }
            }
    
            if(name === 'confirm_password'){
                if(value !== this.state.password){
                    error = this.setErrorField(error, name, true, 'La contraseña no coiciden');
                }
            }
        }
        

        this.setState({error})

    }

    setErrorField(error, name, status, message){
        error[name].error = status;
        error[name].text = message;
        this.setState({blockButton: status});
        return error;
    }

    handleClickShowPassword = (type) => () => {
        let showPassword = {...this.state.showPassword}
        if(type === 'P'){
            showPassword.pass = !showPassword.pass;
        }else{
            showPassword.confirm = !showPassword.confirm;
        }
        this.setState({showPassword});
    };

    handleMouseDownPassword(event) {
        event.preventDefault();
    };

    validEmail(emailValue) {
        let result = false;
        // let reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
        let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (reg.test(emailValue)) {
            result = true;
        }
        return result;
    }

    register = () => () => {
        if(this.validateFields()){
            this._registerUser();
        }
    }

    _registerUser(){
        firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
        .then((res)=>{
            this.updateUser(res, this);   
        })
        .catch((err) => {
            console.error(err);
            if(err.code === 'auth/email-already-in-use'){
                toast.error('La dirección de correo electrónico ya está siendo utilizada por otra cuenta.');
            }else{
                toast.error('Error al registrar el usuario');
            }
        })
    }

    updateUser(res, component){
        res.user.updateProfile({
            displayName: this.state.name
        }).then(() => {
            console.log(firebase.auth().currentUser);
            toast.info('Usuario registro correctamente');
            component.props.navigate('/');
        }, (error) => {
            console.error(error);
        });
    }

    validateFields(){
        let validation = true;
        let error = {...this.state.error};

        if(this.state.name === ''){
            error.name.error = true;
            error.name.text = 'Campo obligatorio';
            validation = false;
        }

        if(!this.validEmail(this.state.email)){
            error.email.error = true;
            error.email.text = 'Email incorrecto';
            validation = false;
        }
        
        if(this.state.password.length < 6){
            error.password.error = true;
            error.password.text = 'La contraseña debe tener mas de 6 caracteres';
            validation = false;
        }

        

        if(this.state.confirm_password !== this.state.password){
            error.confirm_password.error = true;
            error.confirm_password.text = 'La contraseña no coiciden';
            validation = false;
        }

        if(!validation){
            this.setState({error, blockButton: true})
        }else{
            this.setState({error, blockButton: false})
        }
        return validation;
    }

    render(){
        let showImage  = true;
        if(window.screen.width <= 480){
            showImage = false;
        }
        return(
            <Container>
                <div className="login-div">
                    <Card>
                        <CardContent>
                            <div>
                                <div className="div-element">
                                    <h5>Registrate con nosotros</h5>
                                </div>
                                <div className="div-element">
                                    <TextField
                                        name='email'
                                        label='Email'
                                        value={this.state.email ? this.state.email : ""}
                                        error={this.state.error.email.error}
                                        helperText={this.state.error.email.error ? this.state.error.email.text : ''}
                                        fullWidth
                                        margin="normal"
                                        required={this.state.error.email.required}
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputBlur}
                                    />
                                    <TextField
                                        name='name'
                                        label='Nombre'
                                        value={this.state.name ? this.state.name : ""}
                                        error={this.state.error.name.error}
                                        helperText={this.state.error.name.error ? this.state.error.name.text : ''}
                                        fullWidth
                                        margin="normal"
                                        required={this.state.error.name.required}
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputBlur}
                                    />
                                    <TextField
                                        type={this.state.showPassword.pass ? 'text' : 'password'}
                                        name='password'
                                        label='Contraseña'
                                        value={this.state.password ? this.state.password : ""}
                                        error={this.state.error.password.error}
                                        helperText={this.state.error.password.error ? this.state.error.password.text : ''}
                                        fullWidth
                                        margin="normal"
                                        required={this.state.error.password.required}
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputBlur}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword('P')}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                                >
                                                {this.state.showPassword.pass ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                    />
                                    <TextField
                                        type={this.state.showPassword.confirm ? 'text' : 'password'}
                                        name='confirm_password'
                                        label='Confirmar contraseña'
                                        value={this.state.confirm_password ? this.state.confirm_password : ""}
                                        error={this.state.error.confirm_password.error}
                                        helperText={this.state.error.confirm_password.error ? this.state.error.confirm_password.text : ''}
                                        fullWidth
                                        margin="normal"
                                        required={this.state.error.confirm_password.required}
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputBlur}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword('C')}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                                >
                                                {this.state.showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                    />
                                </div>
                                <div className="div-element">
                                    <ColorButton style={{width: '100%'}} 
                                        variant="contained" 
                                        onClick={this.register()} 
                                        disabled={this.state.blockButton}
                                    >Registrarse</ColorButton>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Container>
        )
    }
}
