import React from 'react';
import { Container, Grid, Card, CardContent, CardMedia, CardActions, Rating }  from '@mui/material';

class Footer extends React.Component {

    constructor(props){
        super(props);
        this.state ={
            tooltipOpen1: false,
            tooltipOpen2: false,
            permission: false
        }
    }

    render(){
        const { title, rating } = this.props;
        let date = new Date();
        let year = date.getFullYear();
        return(
          <div style={{backgroundColor: '#2C2C2C', paddingTop: 50, paddingBottom: 50}}>
            <Container>
              <div className='div-footer'>
                <div style={{color: '#fff', fontWeight: 'bold', marginBottom: 20}}>Lenguaje Corporal en Español</div>
                <div className='text-footer'>
                  Somos la única plataforma e-learning en español especializada en lenguaje corporal
                  y microexpresiones faciales. Te aseguramos un aprendizaje total.
                </div>
                <div className='text-footer'>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>Términos y condiciones</Grid>
                    <Grid item xs={12} md={6}>Uso de información</Grid>
                  </Grid>
                </div>
                <div className='text-footer'>
                  Copyright {year}. Todos los derechos reservados.
                </div>
              </div>
            </Container>
          </div>
        )
    }
}
export default Footer;