import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import Preloader from '../../components/Preloader';


export default class Logout extends Component {
	componentDidMount () {
		firebase.auth().signOut()
			.then(() => {
				console.log('salir');
                this.props.navigate('/');
				window.location.reload();
			})
			.catch(error => {
                console.error(error);
                this.props.navigate('/');
			})
	}

	render () {
		return (<Preloader/>);
	}
}
