import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem, ListItemIcon, Avatar } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Logout from '@mui/icons-material/Logout';
import '../App.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { userService } from '../services/user.service';

export default class UserNav extends Component{

    constructor(){
        super();
        this.state = {
            user: '',
            openMenuUser: false,
            anchorEl: null,
            isLogin: false
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount(){
        firebase.auth().onAuthStateChanged((user) => {
            if(user !== null){
                let user = firebase.auth().currentUser;
                this.setState({user, isLogin: true});
            }
        })
    }

    handleClick(event){
        console.log('llega aquu');
        this.setState({
            anchorEl: event.currentTarget,
            openMenuUser: !this.state.openMenuUser
        })
    };
    
    handleClose(){
        this.setState({anchorEl: null, openMenuUser: false})
    };

    redirect = (page) => () => {
        this.props.navigate(page);
    }

    render(){
        if(this.state.isLogin){
            return(
                <div>
                    <Button 
                        variant="text" 
                        style={{color: '#000'}} 
                        endIcon={<Avatar src={this.state.user.photoURL}/>}
                        aria-controls={this.state.openMenuUser ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={this.state.openMenuUser ? 'true' : undefined}
                        onClick={this.handleClick}
                    >
                        {this.state.user.displayName}
                    </Button>
                    <Menu
                        anchorEl={this.state.anchorEl}
                        id="account-menu"
                        open={this.state.openMenuUser}
                        onClose={this.handleClose}
                        onClick={this.handleClose}
                        PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            },
                            '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem component={Link} to="/office">
                            <ListItemIcon>
                                <PersonOutlineIcon fontSize="small" />
                            </ListItemIcon>
                            Mi perfil
                        </MenuItem>
                        <MenuItem component={Link} to="/logout">
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </div>
            )
        }else{
            return(
                <div style={{padding: 10}}>
                    <Button 
                        variant="outlined" 
                        size="small"
                        color="primary"
                        onClick={this.redirect('/login')}
                    >
                        Inicia sesión
                    </Button>
                    <Button 
                        variant="contained" 
                        size="small"
                        color="primary"
                        style={{marginLeft: 15}}
                        onClick={this.redirect('/register')}
                    >
                        Registrate
                    </Button>
                </div>
            )
        }
    }
}
