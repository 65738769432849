
export default class Helper {

    constructor(){

    }

    dimensionWindow() {
        let tam = {width: 0, height: 0};
        if (typeof window.innerWidth !== 'undefined')
        {
          tam = {width: window.innerWidth, height: window.innerHeight};
        }
        else if (typeof document.documentElement !== 'undefined'
            && typeof document.documentElement.clientWidth !==
            'undefined' && document.documentElement.clientWidth !== 0)
        {
          tam = {
              width: document.documentElement.clientWidth,
              height: document.documentElement.clientHeight
          };
        }
        else   {
          tam = {
              width: document.getElementsByTagName('body')[0].clientWidth,
              height: document.getElementsByTagName('body')[0].clientHeight
          };
        }
        return tam;
    }

    generateDate(){
        let date = new Date();
        let day = this.addCero(date.getDate());
        let month = this.addCero(date.getMonth() + 1);
        let hour = this.addCero(date.getHours());
        let minute = this.addCero(date.getMinutes());
        return date.getFullYear()+'-'+month+'-'+day+' '+hour+':'+minute;
    }

    addCero(value){
        if(parseInt(value) < 10){
            value = '0'+value;
        }
        return value;
    }
}