import React, {Component} from 'react';
import { Container, Grid, Button, FormControl, InputLabel, OutlinedInput, 
    InputAdornment, IconButton,FormHelperText, Card, CardContent, Fab
} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SectionSlider from '../../components/SectionSlider';

import '../../App.css';
import fondo from '../../assets/fondologin.jpg';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
import { getAuth, signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'

const styles = {
    paperContainer: {
        backgroundImage: `url(${fondo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    }
};

export default class Login extends Component{

    constructor(){
        super();
        this.state={
            email: '',
            password: '',
            showPassword: '',
            errorEmail: false,
            errorPass: false,
            message: []
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.login = this.login.bind(this);
        this.loginGoogle = this.loginGoogle.bind(this);
        this.loginFacebook = this.loginFacebook.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    login(){
        let validation = this.validateFields();
        if(validation){
            signInWithEmailAndPassword(this.state.email,this.state.password)
            .then(() => {
                this.props.navigate('/');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                if(errorCode === 'auth/wrong-password'){
                    toast.error('Contraseña incorrecta');
                }else{
                    toast.error('El usuario no se encuentra registrado');
                }
            })
        }
    }

    loginGoogle(){
        console.log('login google')
        const provider = new GoogleAuthProvider();
        this.loginSocialNetwork(provider);
    }
    
    loginFacebook(){
        console.log('login facebook')
        const provider = new FacebookAuthProvider();
        this.loginSocialNetwork(provider);
    }

    loginSocialNetwork(provider){
        const auth = getAuth();
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // const credential = GoogleAuthProvider.credentialFromResult(result);
            // const token = credential.accessToken;
            // // The signed-in user info.
            // const user = result.user;
            // console.log(user, token, credential);
            this.props.navigate('/');
            // ...
        }).catch((error) => {
            // Handle Errors here.
            // const errorCode = error.code;
            const errorMessage = error.message;
            toast.error(errorMessage);
            // // The email of the user's account used.
            // const email = error.customData.email;
            // // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            // // ...
        });
    }

    validateFields(){
        let validation = true;

        if(this.state.email == ''){
            toast.error('El campo email es obligatorio');
            validation = false;
        }else{
            let validEmail = /@/.test(this.state.email);
            if(!validEmail){
                toast.error('Email incorrecta');
                validation = false;
            }
        }
        if(this.state.password == ''){
            toast.error('El campo contraseña es obligatorio');
            validation = false;
        }else{
            if(this.state.password.length < 6){
                toast.error('La contraseña debe tener mas de 6 caracteres');
                validation = false;
            }
        }
        return validation;
    }

    handleClickShowPassword() {
        this.setState({
            showPassword: !this.state.showPassword,
        });
    };

    handleMouseDownPassword(event) {
        event.preventDefault();
    };

    render(){
        let showImage  = true;
        if(window.screen.width <= 480){
            showImage = false;
        }

        return(
            <Container>
                <div className="login-div">
                    <Card>
                        <CardContent>
                            <div>
                                <div className="div-element">
                                    <h5>Inicia sesion en tu cuenta</h5>
                                </div>
                                <div className="div-element">
                                    <FormControl variant="outlined" fullWidth margin="normal">
                                        <InputLabel htmlFor="email">Email</InputLabel>
                                        <OutlinedInput
                                            id="email"
                                            name="email"
                                            type='email'
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                            label="Email"
                                            error={this.state.errorEmail}
                                        />
                                        {this.state.errorEmail && (
                                        <FormHelperText style={{color: 'red'}}>We'll never share your email.</FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl variant="outlined" fullWidth margin="normal">
                                        <InputLabel htmlFor="password">Contraseña</InputLabel>
                                        <OutlinedInput
                                            id="password"
                                            name="password"
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                                >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                            label="Contraseña"
                                        />
                                    </FormControl>
                                </div>
                                <div style={{marginBottom: 10}}>
                                    <Button style={{width: '100%'}} variant="contained" onClick={this.login.bind()}>Ingresar</Button>
                                </div>
                                <div className="div-element">
                                   <Link style={{textAlign: 'right'}} to='/changepassword'>Olvidaste su contraseña?</Link>
                                </div>
                                <div className="div-element">
                                ¿No tienes una cuenta? <Link style={{textAlign: 'right'}} to='/register'>Regístrate</Link>
                                </div>
                                <div className="div-element">
                                    Continua con: 
                                    <div style={{textAlign: 'center'}}>
                                        <Fab color="error" size="small" onClick={this.loginGoogle}>
                                            <GoogleIcon/>
                                        </Fab>{' '}
                                        <Fab color="info" size="small" onClick={this.loginFacebook}>
                                            <FacebookIcon/>
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                
            </Container>
        )
    }
}
