import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class UserService extends BaseService{

    constructor(){
        super();
        this.collection = 'users';
    }

    getUser(userId){
        return firebase.firestore()
        .collection(this.collection)
        .doc(userId)
        .get();
    }
}

export const userService = new UserService();
