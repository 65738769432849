import React from 'react';
import { Grid, ButtonGroup, Button, TextField } from '@mui/material';
import '../App.css';

import logoCurso from '../assets/logo-curso.png';
import logo from '../assets/logo.png';

import UserNav from '../components/UserNav';
import MenuNav from '../components/MenuNav';

class Header extends React.Component{

    constructor(){
        super();
        this.state = {  
            search: ''
        }
    }

    redirect = (page) => () => {
        this.props.navigate(page);
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    render(){
        let showMenuButton = false;
        let showMenuImage = false;
        let dimensionsGridXs = [2, 0, 10];
        if(window.screen.width <= 480){
            showMenuButton = true;
            showMenuImage = true;
        }
        if(window.screen.width > 480 && window.screen.width <= 959){
            dimensionsGridXs = [1, 6.5, 4.5];
            showMenuImage = true;
        }
        return (
            <Grid item xs={12} md={12}>
                <div className='header'>
                    <Grid item container>
                        {/* {showMenuButton && (
                            <Grid item xs={2} style={{padding: 8}}>
                                <MenuNav/>
                            </Grid>
                        )} */}
                        <Grid item xs={dimensionsGridXs[0]} md={2.5} style={{padding: 4}}>
                            <div style={{cursor: 'pointer'}} onClick={this.redirect('/')}>
                                {showMenuImage ? 
                                    (<img src={logo} height="50"/>):
                                    (<img src={logoCurso} height="50"/>)
                                }
                            </div>
                        </Grid>
                        {!showMenuButton &&
                            <Grid item xs={dimensionsGridXs[1]} md={6.5} style={{padding: 10}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={5} md={6}>
                                        <TextField 
                                            type="text" 
                                            name="search" 
                                            label="Buscar"
                                            value={this.state.search} 
                                            onChange={(event) => this.handleInputChange(event)} 
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={3.5} md={3}>
                                        <Button variant='text' className='text-menu'>
                                            Para personas
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3.5} md={3}>
                                        <Button variant='text' className='text-menu'>
                                            Para empresas
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={dimensionsGridXs[2]} md={3}>
                            <div style={{textAlign: 'right', padding: 4, alignItems: 'center'}}>
                                <UserNav navigate={this.props.navigate}/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        )
    }
}
export default Header;